html {
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  color-scheme: dark;
}
:root {
  --color-primary: #b7001c;
  --color-incoming-transaction: #4e9800;
  --color-outgoing-transaction: #e02020;
  --color-table-row-hover: rgba(255,255,255,0.05);
  --color-orange-red: #FF4440;
  --color-wells: #242424;
  --darken: rgba(0, 0, 0, 0.30);
  --color-whitesmoke: #2e2f3b;
  --color-white: #ffffff;
  --text-color: #e1e1ff;
  /* White with alpha */
  --color-secondary-alpha-01: rgba(255, 255, 255, 0.01);
  --color-secondary-alpha-02: rgba(255, 255, 255, 0.02);
  --color-secondary-alpha-05: rgba(255, 255, 255, 0.05);
  --color-secondary-alpha-10: rgba(255, 255, 255, 0.10);
  --color-secondary-alpha-15: rgba(255, 255, 255, 0.15);
  --color-secondary-alpha-20: rgba(255, 255, 255, 0.20);
  --color-secondary-alpha-25: rgba(255, 255, 255, 0.25);
  --color-secondary-alpha-30: rgba(255, 255, 255, 0.30);
  --color-secondary-alpha-40: rgba(255, 255, 255, 0.40);
  --color-secondary-alpha-50: rgba(255, 255, 255, 0.50);
  --color-secondary-alpha-60: rgba(255, 255, 255, 0.60);
  --color-secondary-alpha-70: rgba(255, 255, 255, 0.70);
  --color-secondary-alpha-80: rgba(255, 255, 255, 0.80);
  --color-secondary-alpha-85: rgba(255, 255, 255, 0.85);
  /* Properties */
  --custom-border-radius: 7px;
}

/* Simple link */
.simplelink {
  color: inherit;
  text-decoration: none;
}
.simplelink:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.simplelink:focus {
  outline: none;
}
.simplelink.underline {
  text-decoration: underline;
}
.simplelink.underline-on-hover:hover {
  text-decoration: underline !important;
}

/* Stream styles */
.stream-type-incoming {
  color: var(--color-incoming-transaction);
}
.stream-type-outgoing {
  color: var(--color-outgoing-transaction);
}
.money-streams-widget-wrapper {
  position: relative;
  overflow: hidden;
  margin-top: -10px;
  margin-bottom: -10px;
}
.money-streams-widget-content {
  position: relative;
  height: auto;
  min-height: 500px;
  border-bottom-left-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
  background-color: inherit !important;
}
.money-streams-widget-wrapper .grape-paper {
  padding: 0 !important;
}
.position-relative {
  position: relative;
}
.click-disabled {
  pointer-events: none;
}
.meanfi-panel-blurry {
  background: rgba(0, 0, 0, 0.15) !important;
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
  backdrop-filter: blur(4px) !important;
}
.meanfi-sliding-panel {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 24px !important;
  height: 100%;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.meanfi-sliding-panel.open {
  display: block;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1099;
}
.text-truncate {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stream-activity-table {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  min-height: 100px;
  max-height: 192px;
}

/* Custom input wells */
.well {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  border-radius: var(--custom-border-radius);
  background-color: var(--color-wells);
  padding: 12px 15px;
  margin-bottom: 24px;
  min-height: 50px;
}
.well.disabled {
  pointer-events: none;
  opacity: 0.6;
  background-color: var(--color-secondary-alpha-02);
}
.well:not(.disabled):hover {
  background-color: var(--darken);
}
.well .add-on {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.well .add-on .token-max {
  margin: 0 8px;
  min-width: 30px;
  padding: 3px 6px;
  color: var(--color-white);
  font-weight: 800;
  font-size: 10px;
  line-height: 11px;
  white-space: nowrap;
  text-align: center;
  background: var(--color-primary);
  border-radius: var(--custom-border-radius);
}
.well .add-on .token-max:hover {
  color: var(--color-white);
}
.well .static-data-field {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  .well .static-data-field {
    font-size: 18px;
  }
}
.well .general-text-input {
  width: 100%;
  position: relative;
  color: var(--text-color);
  font-weight: 500;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 20px;
  line-height: 20px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  appearance: textfield;
}
.well .general-text-input[readonly] {
  cursor: not-allowed;
}
@media (max-width: 575.98px) {
  .well .general-text-input {
    font-size: 18px;
  }
}

.token-group {
  display: flex;
  align-items: center;
}
.token-group .token-max {
  margin-right: 8px;
  min-width: 30px;
  padding: 3px 6px;
  color: var(--color-white);
  font-weight: 800;
  font-size: 10px;
  line-height: 11px;
  white-space: nowrap;
  text-align: center;
  background: var(--color-primary);
  border-radius: var(--custom-border-radius);
}
.token-group .token-max.disabled {
  color: var(--color-secondary-alpha-40);
  background-color: var(--color-whitesmoke);
  pointer-events: none;
}
.token-group .token-max:hover {
  color: var(--color-white);
}

/* Flex convenient classes */
.flex-fixed-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-fixed-right.align-items-center {
  align-items: center;
}
.flex-fixed-right .left {
  flex: 1 1 auto;
  margin-right: 1rem;
}
.flex-fixed-right .right {
  flex: 0 0 auto;
}

/* Custom form helpers */
.form-label {
  width: 100%;
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--color-secondary-alpha-60);
  line-height: 1.3rem;
  margin-bottom: 8px;
  padding-left: 4px;
}
.form-field-error {
  display: block;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--color-orange-red);
  margin: 4px 0 -4px 0;
}
.form-field-hint {
  display: block;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: var(--color-secondary-alpha-30);
  margin: 5px 0 -5px 1px;
}

/* Custom pills */
.pill {
  min-width: 24px;
  height: 24px;
  color: white;
  background-color: rgba(255, 255, 255, 0.16);
  font-weight: 900;
  font-size: 0.8rem;
  white-space: nowrap;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.pill:hover {
  color: white;
}
.pill.small {
  font-size: 10px;
  padding: 2px 4px 3px;
  border-radius: 5px;
  line-height: 10px;
  height: auto;
  font-weight: 400;
}
.pill.medium {
  font-size: 11px;
  padding: 4px 5px;
  border-radius: 8px;
  line-height: 11px;
  height: auto;
}

/* Create hover effect for any row element */
.hoverable-row {
  -webkit-transition: .1s all;
  transition: .1s all;
  cursor: pointer;
}
.hoverable-row:hover,
.hoverable-row:focus {
  background-color: var(--color-table-row-hover);
}

/* Custom background for INCOMING/OUTGOING stream details */
.grape-paper.incoming-stream-detail {
  background-color: black !important;
  background-image: linear-gradient(rgb(61, 241, 61, 0.18), rgb(43, 76, 8, 0.37)) !important;
}
.grape-paper.outgoing-stream-detail {
  background-color: black !important;
  background-image: linear-gradient(rgb(205, 37, 37, 0.44), rgb(118, 49, 49, 0.15%)) !important;
}
.incoming-stream-detail .stream-activity-table .stream-activity-table-header {
  background-color: #0d1a00;
}
.outgoing-stream-detail .stream-activity-table .stream-activity-table-header {
  background-color: #1f0000;
}

/* Custom bounce animation */
.bounce {
  animation: bounce 0.6s infinite alternate;
  -webkit-animation: bounce 0.6s infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(4px);
  }
  to {
    transform: translateY(-4px);
  }
}

@-webkit-keyframes bounce {
  from {
    transform: translateY(4px);
  }
  to {
    transform: translateY(-4px);
  }
}
